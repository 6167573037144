define('hadoop/hadoop-wrapper',['edap', 'domhelpers', 'doneable'], function(edap, domHelpers, Doneable) {
    'use strict';

    function Hadoop() {
        /**
         *  Constant value for a missing required field (matches api-operations-client-logging)
         */
        this.missingDefaultValue = 'NA';

        /**
         *  Beacon-type to url mapping object
         */
        this.urls = {
            'beacon': '/edap/elo/v1/event/beacon',
            'img': '/edap/elo/v1/event/img'
        };

        /**
         *  Gets a user's session id
         *  Due to the lack of a unified web session for unauthenticated users this *may* break...
         *
         *  @example
         *    var sid = getHasId();
         *
         *  @returns {String} - Session ID from the associated brand session cookie or this.missingDefaultValue
         */
        this.getHasId = function() {
            var hadoop = this,
                sid = domHelpers.getCookie('has');

            if (sid === null) {
                sid = hadoop.missingDefaultValue;
            }

            return sid;
        };


        /**
         *  Gets a user's visitor id
         *  Due to the lack of a unified web session for unauthenticated users this *may* break...
         *
         *  @example
         *    var sid = getHavId();
         *
         *  @returns {String} - Visitor ID from the associated brand visitor cookie or this.missingDefaultValue
         */
        this.getHavId = function() {
            var hadoop = this,
                vid = domHelpers.getCookie('hav');

            if (vid === null) {
                vid = hadoop.missingDefaultValue;
            }

            return vid;
        };


        /**
         *  Check that required fields are present
         *
         *  @param {String} [eventName] - The EDAP event name
         *  @param {String} [sessionId] - Session ID to send to Hadoop
         *  @param {String} [visitorId] - Visitor ID to send to Hadoop
         *  @param {Object} [payload] - *optional* Object of data to be JSON.stringify()'d
         *
         *  @example
         *    var valid = checkData('user.email.entered', '1234-1234', '5678-5678', {listingid: '123.1234.1234'});
         *
         *  @returns {Boolean} - True if expected fields are present and appropriate data types. False if not.
         */
        function checkData(eventName, sessionId, visitorId, payload) {
            if (typeof eventName !== 'string') {
                edap.error('hadoop.buildUrl requires that "eventName" be a string: ' + eventName);
                return false;
            }

            if (typeof sessionId !== 'string') {
                edap.error('hadoop.buildUrl requires that "sessionId" be a string: ' + sessionId);
                return false;
            }

            if (typeof visitorId !== 'string') {
                edap.error('hadoop.buildUrl requires that "visitorId" be a string: ' + visitorId);
                return false;
            }

            // Payload is optional, but if it is set then it needs to be an object
            if (payload && typeof payload !== 'object') {
                edap.error('hadoop.buildUrl requires that "payload" be an object, if set: ' + sessionId);
                return false;
            }

            return true;
        }

        /**
         *  Creates the structured object used by buildBeaconBlob or buildUrl to send params
         *
         *  @param {String} [eventName] - The EDAP event name
         *  @param {String} [sessionId] - Session ID to send to Hadoop
         *  @param {String} [visitorId] - Visitor ID to send to Hadoop
         *  @param {Object} [payload] - Analytics data object
         *  @param {Object} [optionalParams] - *optional* Object of optional parameter data to be added
         *
         *  @example
         *    var analyticsObj = createDataObject('user.email.entered', '1234-1234', '5678-5678', {listingid: '123.1234.1234'}, {emailaddress: 'test@test.com'});
         *
         *  @returns {Object} - structured object returned to be converted into a blob or an IMG url
         */
        this.createDataObject = function(eventName, sessionId, visitorId, payload, optionalParams) {
            var analyticsObj = {
                    type: 'edap:' + eventName,
                    sessionId: sessionId,
                    visitorId: visitorId,
                    site: this.missingDefaultValue,
                    payload: payload
                },
                n = null,
                // Maps EDAP data names to URL Query Parameter names
                // [optionalParams key name, URL param name]
                optionalParamMapping = {
                    emailaddress: 'emailAddress',
                    firstname: 'firstName',
                    lastname: 'lastName'
                },
                key;

            // Default to an object just to be safe
            optionalParams = optionalParams || {};

            if (!checkData(eventName, sessionId, visitorId, payload)) {
                return n;
            }

            if (payload && payload.hasOwnProperty('monikerbrand')) {
                analyticsObj.site = payload.monikerbrand;
            }

            for (key in optionalParamMapping) {
                if (optionalParamMapping.hasOwnProperty(key) && optionalParams.hasOwnProperty(key)) {
                    analyticsObj[optionalParamMapping[key]] = optionalParams[key];
                }
            }

            return analyticsObj;
        };

        /**
         *  Builds the text blob to "send" via sendBeacon. The analytics payload will be stringified and sent as type: 'text/plain'
         *  because of https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Simple_requests and CORS-safelisted request-header
         *
         *  @example
         *    var beaconBlob = buildBeaconBlob('user.email.entered', '1234-1234', '5678-5678', {listingid: '123.1234.1234'});
         *
         *  @param {String} [eventName] - The EDAP event name
         *  @param {String} [sessionId] - Session ID to send to Hadoop
         *  @param {String} [visitorId] - Visitor ID to send to Hadoop
         *  @param {Object} [payload] - *optional* Object of data to be JSON.stringify()'d
         *  @param {Object} [optionalParams] - *optional* Object of optional parameter data to be added
         *  @returns {Object} - Stringified blob of data to be sent via navigator.sendBeacon
         */
        this.buildBeaconBlob = function(eventName, sessionId, visitorId, payload, optionalParams) {
            var analyticsObj = this.createDataObject(eventName, sessionId, visitorId, payload, optionalParams);

            if (analyticsObj === null) {
                return analyticsObj;
            }

            return new Blob(
                [JSON.stringify(analyticsObj)],
                {type: 'text/plain'} // b/c of https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Simple_requests and CORS-safelisted request-header
            );
        };


        /**
         *  Builds the entire Hadoop url to "send" via an image request. All query parameters will URI encoded.
         *
         *  @example
         *    var imageUrl = buildUrl('user.email.entered', '1234-1234', '5678-5678', {listingid: '123.1234.1234'});
         *
         *  @param {String} [eventName] - The EDAP event name
         *  @param {String} [sessionId] - Session ID to send to Hadoop
         *  @param {String} [visitorId] - Visitor ID to send to Hadoop
         *  @param {Object} [payload] - *optional* Object of data to be JSON.stringify()'d
         *  @param {Object} [optionalParams] - *optional* Object of optional parameter data to be added to the URL
         *  @returns {String} - url path for the Hadoop endpoint with data encoded in query parameters, or null if there was an error
         */
        this.buildUrl = function(eventName, sessionId, visitorId, payload, optionalParams) {
            var analyticsObj = this.createDataObject(eventName, sessionId, visitorId, payload, optionalParams),
                win = domHelpers.getWindow(),
                paramList = [],
                url = this.urls.img,
                encodedPayloadContent,
                prop;

            if (analyticsObj === null) {
                return analyticsObj;
            }

            // Add all of the analytics object properties as key=encodedValue other than payload
            for (prop in analyticsObj) {
                if (analyticsObj.hasOwnProperty(prop) && prop !== 'payload') {
                    paramList.push(prop + '=' + win.encodeURIComponent(analyticsObj[prop]));
                }
            }

            // Stringify, encode and push any payload data(already validated to be an object if set)
            if (analyticsObj.payload && win.JSON && typeof win.JSON.stringify === 'function') {
                try {
                    encodedPayloadContent = win.encodeURIComponent(win.JSON.stringify(analyticsObj.payload));
                } catch (err) {
                    edap.error(err);
                    encodedPayloadContent = win.encodeURIComponent('{"error":"Could not JSON.stringify payload"}');
                }

                paramList.push('payload=' + encodedPayloadContent);
            }

            paramList.push('_restfully=true');

            url = url + '?' + paramList.join('&');
            return url;
        };


        /**
         *  Determines if the browser has navigator.sendBeacon()
         *
         *  @example
         *    var hasSendBeacon = hasSendBeacon();
         *
         *  @TECHDEBT: Disabled in Chrome temporarily due to https://bugs.chromium.org/p/chromium/issues/detail?id=958108
         *  @returns {Boolean} - true: if navigator.sendBeacon exists and the userAgent isn't Chrome, otherwise false
         */
        this.hasSendBeacon = function() {
            var nav = domHelpers.getNavigator(),
                rex = RegExp('\\bChrome\\b');

            return typeof nav.sendBeacon === 'function' && !rex.test(nav.userAgent);
        };


        /**
         *  "Sends" a Hadoop request via an IMG pixel
         *
         *  @example
         *    var sendDoneable = send('user.email.entered', {listingid: '123.1234.1234'}, null, null);
         *
         *  @param {String} [eventName] - The EDAP event name
         *  @param {Object} [data] - Object of data to be JSON.stringify()'d
         *  @param {String} [sessionId] - *optional* Session ID to send to Hadoop, will be filled in if set to null (ex. ADL.sessionid)
         *  @param {String} [visitorId] - *optional* Visitor ID to send to Hadoop, will be filled in if set to null (ex. ADL.visitorid)
         *  @returns {Doneable} - Then-able object so you can associate callbacks upon completion
         */
        this.send = function(eventName, data, sessionId, visitorId) {
            var that = this,
                optionalParams = {},

                // Data to copy/move from data to optionalParams.
                // If found in data then they will be moved to optionalParams and removed from data
                optionalPIIParamsFromData = ['emailaddress', 'firstname', 'lastname'],

                // Keys that should be removed from the data payload before sending
                // Currently these are the Ensighten 1 tracking objects that don't need to be pushed into Hadoop
                removeFromData = ['gaevents', 'thirdparty', 'criteoevents'];

            return new Doneable(function(done) {
                var url,
                    key,
                    i,
                    len,
                    beaconBlob,
                    beaconResult;

                if (!sessionId) {
                    sessionId = that.getHasId();
                }

                if (!visitorId) {
                    visitorId = that.getHavId();
                }

                if (data) {
                    for (i = 0, len = optionalPIIParamsFromData.length; i < len; i++) {
                        key = optionalPIIParamsFromData[i];

                        if (data.hasOwnProperty(key)) {
                            optionalParams[key] = data[key];
                            delete data[key];
                        }
                    }

                    // Now let's remove other keys that don't need to go to Hadoop
                    for (i = 0, len = removeFromData.length; i < len; i++) {
                        key = removeFromData[i];

                        if (data.hasOwnProperty(key)) {
                            delete data[key];
                        }
                    }
                }

                // Do sendBeacon things if you should
                if (that.hasSendBeacon() && eventName !== 'user.email.entered') {
                    beaconBlob = that.buildBeaconBlob(eventName, sessionId, visitorId, data, optionalParams);
                    beaconResult = domHelpers.sendBeacon(that.urls.beacon, beaconBlob, done);
                    // If true is returned from the sendBeacon queue of data return. Otherwise fall back to the image.
                    if (beaconResult) {
                        return;
                    }
                }

                url = that.buildUrl(eventName, sessionId, visitorId, data, optionalParams);
                domHelpers.getImage(url, done);
            }, {
                error: function(err) {
                    edap.error(err);
                }
            });
        };
    }

    return new Hadoop();
});

